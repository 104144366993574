export default {
  SET_USERS (state, usersData) {
    state.users = usersData['hydra:member']
    state.totalItems = usersData['hydra:totalItems']
  },
  ADD_USER (state, user) {
    state.users.push(user)
    state.totalItems++
  },
  REMOVE_USER (state, itemId) {
    const userIndex = state.users.findIndex((u) => u.id === itemId)
    state.users.splice(userIndex, 1)
    state.totalItems--
  },
  UPDATE_USER (state, user) {
    const userIndex = state.users.findIndex((u) => u.id === user.id)
    state.users[userIndex] = user
  }
}
