<template>
  <div id="user-list">
    <div class="vx-card p-6">
      <vs-table class="w-100 my-4" stripe sst max-items="3" :data="usersData" @sort="onTableSort">
        <template slot="header">
            <!-- ITEMS PER PAGE -->
            <div class="flex-grow my-3">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                  <span class="mr-2">{{ $t('administration.userlist.row_by_page_switcher', { rowStart, rowEnd, totalRow: totalItems }) }}</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item v-for="pageSize in pageSizeList" @click="paginationPageSize=pageSize" :key="pageSize">
                    <span>{{ pageSize }}</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
            <vs-button class="mr-8" type="relief" icon="person_add" :to="{name:'user-add'}" >{{ $t('administration.userlist.link.create_user') }}</vs-button>

            <!-- TABLE ACTION COL-2: SEARCH -->
            <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" :placeholder="$t('administration.userlist.search.placeholder')" />
        </template>

        <template slot="thead">
          <vs-th sort-key="username">{{ $t('administration.userlist.table.header.username') }}</vs-th>
          <vs-th sort-key="firstname">{{ $t('administration.userlist.table.header.firstname') }}</vs-th>
          <vs-th sort-key="lastname">{{ $t('administration.userlist.table.header.lastname') }}</vs-th>
          <vs-th sort-key="email">{{ $t('administration.userlist.table.header.email') }}</vs-th>
          <vs-th sort-key="groups.name">{{ $t('administration.userlist.table.header.groups') }}</vs-th>
          <vs-th>{{ $t('administration.userlist.table.header.actions') }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td :data="data[indextr].username">
              <user-list-username :data="data[indextr]" />
            </vs-td>
            <vs-td :data="data[indextr].firstname">
              {{ data[indextr].firstname }}
            </vs-td>
            <vs-td :data="data[indextr].lastname">
              {{ data[indextr].lastname }}
            </vs-td>
            <vs-td :data="data[indextr].email">
              <a :href="'mailto: ' + data[indextr].email">{{ data[indextr].email}}</a>
            </vs-td>
            <vs-td :data="data[indextr].groups">
              <user-list-group :data="data[indextr].groups" />
            </vs-td>
            <vs-td>
              <user-list-actions :data="data[indextr]" />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import moduleUserManagementMixin from '@/store/user-management/moduleUserManagementMixin'

import UserListActions from './cell-renderer/CellRendererActions'
import UserListGroup from './cell-renderer/CellRendererGroups'
import UserListUsername from './cell-renderer/CellRendererUsername'

export default {
  components: {
    UserListUsername,
    UserListGroup,
    UserListActions
  },
  mixins: [moduleUserManagementMixin],
  metaInfo: {
    title: 'Users List'
  },
  data () {
    return {
      currentPage: 1,
      paginationPageSize: 10,
      pageSizeList: [10, 20, 25, 30],
      maxPageNumbers: 7,
      searchQuery: '',
      sortItem: {},
      searchItem: {}
    }
  },
  computed: {
    usersData () {
      return this.$store.state.userManagement.users
    },
    totalItems () {
      return this.$store.state.userManagement.totalItems
    },
    totalPages () {
      return Math.ceil(this.totalItems / this.paginationPageSize)
    },
    rowStart () {
      return (this.currentPage * this.paginationPageSize) - (this.paginationPageSize - 1)
    },
    rowEnd () {
      if (this.usersData.length - (this.currentPage * this.paginationPageSize) > 0) {
        return this.currentPage * this.paginationPageSize
      }

      return this.usersData.length
    }
  },
  watch: {
    '$store.state.windowWidth' (val) {
      if (val <= 576) {
        this.maxPageNumbers = 4
      }
    },
    'currentPage' () {
      this.fetchUsers()
    },
    'paginationPageSize' () {
      if (this.rowStart > this.totalItems) {
        this.currentPage = Math.ceil(this.totalItems / this.paginationPageSize)
        return
      }
      this.fetchUsers()
    }
  },
  methods: {
    updateSearchQuery () {
      if (this.searchQuery === '') {
        this.searchItem = {}
        this.fetchUsers()
        return
      }

      this.searchItem = {
        'email[ior]': this.searchQuery,
        'username[ior]': this.searchQuery,
        'firstname[ior]': this.searchQuery,
        'lastname[ior]': this.searchQuery,
        'groups.name[ior]': this.searchQuery
      }

      this.fetchUsers()
    },
    fetchUsers () {
      const payload = {
        page: this.currentPage,
        itemsPerPage: this.paginationPageSize,
        ...this.sortItem,
        ...this.searchItem
      }
      this.$store.dispatch('userManagement/fetchUsers', payload)
        .catch(err => { console.error(err) }) // eslint-disable-line no-console
    },
    onTableSort (field, order) {
      this.sortItem = {}
      if (order) {
        this.sortItem = {
          order: {
            [field]: order
          }
        }
      }
      this.fetchUsers()
    }
  },
  mounted () {
    this.fetchUsers()
  }
}
</script>

<style scoped>

</style>
