<template>
  <div class="flex items-center">
    <vs-avatar :src="avatarSrc" class="flex-shrink-0 mr-2" size="30px" />
    {{ data.username }}
  </div>
</template>

<script>
export default {
  name: 'UserListUsername',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    avatarSrc () {
      if (!this.data.avatar || !this.data.avatar.contentUrl) {
        return null
      }
      return this.data.avatar.contentUrl
    }
  }
}
</script>
