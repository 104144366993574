import axios from '@/axios.js'
import qs from 'qs'

const cloneDeep = require('lodash.clonedeep')

export default {
  addUser ({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios.post('/users', user)
        .then((response) => {
          commit('ADD_USER', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateUser ({ commit }, user) {
    const userLocal = cloneDeep(user)
    if (userLocal.avatar && userLocal.avatar.id && userLocal.avatar['@id']) {
      userLocal.avatar = userLocal.avatar['@id']
    }
    return new Promise((resolve, reject) => {
      axios.put(`/users/${userLocal.id}`, userLocal)
        .then((response) => {
          commit('UPDATE_USER', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchUsers ({ commit, rootGetters }, payload) {
    if (rootGetters.hasCancelToken('fetchUsers')) {
      rootGetters.cancelToken('fetchUsers').cancel()
    }

    commit('SET_CANCEL_TOKEN', 'fetchUsers', {root: true})

    return new Promise((resolve, reject) => {
      let url = '/users'
      if (payload) {
        url += `?${qs.stringify(payload)}`
      }
      axios.get(url, {
        cancelToken: rootGetters.cancelToken('fetchUsers').token
      }).then((response) => {
        commit('SET_USERS', response.data)
        resolve(response)
      })
        .catch((error) => { reject(error) })
    })
  },
  fetchUser (context, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`/users/${userId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeUser ({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/users/${userId}`)
        .then((response) => {
          commit('REMOVE_USER', userId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
